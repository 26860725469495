import { render, staticRenderFns } from "./role-detail.vue?vue&type=template&id=331c2505&scoped=true&"
import script from "./role-detail.vue?vue&type=script&lang=js&"
export * from "./role-detail.vue?vue&type=script&lang=js&"
import style0 from "./role-detail.vue?vue&type=style&index=0&id=331c2505&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "331c2505",
  null
  
)

export default component.exports