<template>
      <el-dialog
        title="分配接口权限"
        width="900px"
        :visible="showDialog"
        :modal-append-to-body="false"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
      >
      <div class="role-permission">
        <el-transfer
          v-model="relatedPerms"
          :data="permList"
          v-loading="loading"
          filterable
          :filter-method="filterMethod"
          filter-placeholder="请输入关键字"
          :props="{key: 'id',label: 'description'}"
          :titles="['未分配接口','已分配接口']"
        ></el-transfer>
      </div>
        <span slot="footer" class="dialog-footer">
        <el-button @click="handleCancel">取 消</el-button>
        <el-button type="primary" @click="handleSubmit" :loading="submitting">确 定</el-button>
      </span>
    </el-dialog>
</template>
<script>
import roleApi from "@/api/sys/role";
import permissionApi from "@/api/sys/permission";

export default {
  props: ["roleId"],
  data() {
    return {
      permList: [],
      relatedPerms: [],
      showDialog: true,
      loading: false,
      submitting: false
    };
  },
  methods: {
    closeDialog() {
      this.$emit("close",false);
    },
    handleCancel (){
      this.$emit("close",false);
    },
    handleSubmit() {
      var self = this;
      
      self.submitting = true;

      roleApi
        .saveRelatedPermission({
          roleId: this.roleId,
          relatedList: this.relatedPerms
        })
        .then(function(response) {
          var jsonData = response.data;
            self.submitting = false;

          if (jsonData.result) {
            self.$message({
              message: "保存成功!",
              type: "success"
            });

            self.$emit("close",true);
          } else {
            self.$message({
              message: jsonData.message + "",
              type: "warning"
            });

            self.$emit("close",false);
          }
        });
    },
    filterMethod(query, item) {
      return item.description.indexOf(query) > -1;
    },
    queryRelatedPerms() {
      var self = this;
      self.loading = true;

      this.permList = [];
      this.relatedPerms= [];

      permissionApi.selectAll().then(response => {
          var jsonData = eval(response.data);

          this.permList = jsonData.data.map(item => {
            return {
              id: item.id,
              description: item.method + " " + item.path + " " + item.summary
            };
          });

          return roleApi.queryRelatedPerms(this.roleId);
        })
        .then((response) => {
          var jsonData = response.data;

            this.relatedPerms = jsonData.data.map(item => {
              return item.permId;
            });

          self.loading = false;
        });
    }
  },
  mounted() {
    // 采用v-if控制组件是否显示，则会每次显示时执行mounted方法
    this.queryRelatedPerms();
  }
};
</script>
<style lang="scss">
.role-permission {
  .el-transfer-panel {
    border: 1px solid #ebeef5;
    border-radius: 4px;
    overflow: hidden;
    background: #fff;
    display: inline-block;
    vertical-align: middle;
    width: 320px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }
}
</style>
